import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import propTypes from "prop-types";

const AuthRoute = ({
    component: Component,
    layout: Layout,
    pageTitle,
    isLogin,
    isSignup,
    ...rest
}) => {
    const location = useLocation();
    const verifyName = location.pathname == "/verifyname" ? true : false;
    const isAuthenticated = JSON.parse(localStorage.getItem("user"));
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <main>
                        <Layout isSignup={isSignup} isLogin={isLogin}>
                            {isAuthenticated &&
                            isAuthenticated.jwt &&
                            !verifyName ? (
                                <Redirect to="/" />
                            ) : (
                                <Component {...props} />
                            )}
                        </Layout>
                    </main>
                );
            }}
        />
    );
};

AuthRoute.propTypes = {
    component: propTypes.object.isRequired,
};

export default AuthRoute;
