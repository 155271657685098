import React from "react";
import Illustration2 from "assets/images/Saly-3.png";
import Illustration3 from "assets/images/Saly-4.png";
import loaderIllust from "assets/svgs/loader.svg";
import animationData from "assets/lotties/lf20_ppwu5qi1";
import Lottie from "react-lottie";

export default function IllustrationLoader({
    loadingTitle,
    successTitle,
    loadingSubtext,
    successSubtext,
    loading,
    success,
}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <>
            {loading && (
                <div className="illust">
                    <h2>{loadingTitle}</h2>
                    <img
                        src={Illustration2}
                        alt="illustration"
                        className="illust__loading"
                    />
                    <p>{loadingSubtext}</p>
                    <img
                        src={loaderIllust}
                        alt="illustration"
                        className="illust__loader"
                    />
                </div>
            )}

            {success && (
                <div className="illust ">
                    <h2>{successTitle}</h2>
                    <img
                        src={Illustration3}
                        alt="illustration"
                        className="illust__success"
                    />
                    <p>{successSubtext}</p>
                    <Lottie
                        options={defaultOptions}
                        width={250}
                        height={"auto"}
                        position={"absolute"}
                        className={"lottie"}
                    />
                </div>
            )}
        </>
    );
}
