import React, { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import { UserBenefitContext, UserDetailContext } from "context";
import { getBenefits } from "services/benefits";

export const NonSidebarLayout = ({
    navClassName,
    extraClassName,
    pageTitle,
    children,
}) => {
    const [userDetail, setUserDetail] = useState(null);
    const [userBenefit, setUserBenefit] = useState(null);

    const setBenefitLink = (benefit) => {
        switch (benefit.benefitCategory) {
            case "Health Insurance":
                return `/health-insurance/${benefit.benefitId}/${benefit.id}${
                    benefit?.businessBenefit === true
                        ? "?isBusinessBenefit=" + benefit?.businessBenefit
                        : ""
                }`;
            case "Savings":
                return `/savings/${benefit.benefitId}/${benefit.id}${
                    benefit?.businessBenefit === true
                        ? "?isBusinessBenefit=" + benefit?.businessBenefit
                        : ""
                }`;
            case "Gym":
                return `/fitness/${benefit.benefitId}/${benefit.id}${
                    benefit?.businessBenefit === true
                        ? "?isBusinessBenefit=" + benefit?.businessBenefit
                        : ""
                }`;
            default:
                return "/";
        }
    };

    const getUserBenefit = async () => {
        try {
            const response = await getBenefits();
            if (response.data.userBenefits?.length > 0) {
                const benefits = response.data.userBenefits?.map((benefit) => {
                    return {
                        ...benefit,
                        link: setBenefitLink(benefit),
                    };
                });

                setUserBenefit(benefits);
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                setUserBenefit(null);
            }
            return error;
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            const userDetail = {
                id: user.id,
                email: user.username,
                firstname: user.firstname,
                lastname: user.lastname,
                hasActivePlan: user.hasActivePlan,
            };
            setUserDetail(userDetail);
            getUserBenefit();
        }
    }, []);
    return (
        <UserDetailContext.Provider value={{ userDetail, setUserDetail }}>
            <UserBenefitContext.Provider
                value={{ userBenefit, getUserBenefit }}
            >
                <div className="main">
                    <DashboardHeader
                        navClassName={navClassName}
                        pageTitle={pageTitle}
                    />
                    <main className={`nonsidebar__body ${extraClassName}`}>
                        {children}
                    </main>
                </div>
            </UserBenefitContext.Provider>
        </UserDetailContext.Provider>
    );
};
