import { Analytics } from "@segment/analytics-node";
import { v4 as uuidv4 } from "uuid";

export const analytics = new Analytics({
    writeKey: process.env.REACT_APP_SEGMENT_KEY,
});
export const getAnonymousId = () => {
    let anonymousId = localStorage.getItem("anonymousId");
    if (!anonymousId) {
        anonymousId = uuidv4();
        localStorage.setItem("anonymousId", anonymousId);
    }
    return anonymousId;
};
