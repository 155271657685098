import React from "react";
import Icon from "components/Icon";

export default ({
    label,
    type = "text",
    name,
    onChange,
    value = "",
    error,
    placeholder,
    min,
    max,
    id,
    onClick,
    isDisabled,
    onFocus,
    onBlur,
    touched,
    mask,
    maskOptions,
    className,
    isDate = false,
    isFloatingLabel = true,
    size,
    disabled = false,
    onKeyDown,
    isHelperText,
    helperText,
    maxLength,
}) => {
    const [isVisible, setIsVisible] = React.useState(false);
    // const [disabled, setDisabled] = React.useState(false);
    const inputRef = React.useRef(null);
    const labelRef = React.useRef(null);

    React.useEffect(() => {
        if (value !== "") {
            labelRef.current.classList.add("active");
        }
    }, [value]);

    const handleFocus = (e) => {
        labelRef.current.classList.add("active");

        if (onFocus) {
            onFocus(e);
        }
        if (isDate === true) {
            inputRef.current.type = "date";
        }
    };

    const handleBlur = (e) => {
        if (e.target.value === "") {
            labelRef.current.classList.remove("active");
        } else {
            labelRef.current.classList.add("active");
        }

        try {
            onBlur && onBlur(e);
            if (isDate === true) {
                inputRef.current.type = "text";
            }
        } catch (onBlurError) {
            //
        }
    };

    return (
        <div className={`form-group`}>
            <div
                className={`
            ${isFloatingLabel ? "floating-label" : ""} 
            ${className ? className : ""}`}
                ref={labelRef}
            >
                {size !== "small" && <label htmlFor={label}>{label}</label>}
                <input
                    type={isVisible ? "text" : type}
                    className={`form-control ${
                        size === "small" ? "form-control--sm" : ""
                    }`}
                    min={min}
                    max={max}
                    maxLength={maxLength}
                    name={name}
                    onChange={onChange}
                    value={value}
                    ref={inputRef}
                    data-placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onClick={onClick}
                    autoComplete={value}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                />
                {size !== "small" && (
                    <span className="input-icon" id="toggle-icon">
                        {type === "password" && (
                            <Icon
                                id={isVisible ? "eye-icon" : "eye-icon-closed"}
                                width="16"
                                height={isVisible ? "15" : "13"}
                                onClick={() =>
                                    setIsVisible((visible) => !visible)
                                }
                            />
                        )}
                    </span>
                )}
                <small className={`${isHelperText ? "helperText" : ""}`}>
                    {helperText}
                </small>
            </div>
            {error}
        </div>
    );
};
