import api from "./api";

export async function getFlancePasses() {
    try {
        const res = await api.get(`flance/pass`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getFlancePassById(id) {
    try {
        const res = await api.get(`flance/pass/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function purchaseFlancePass(payload) {
    try {
        const res = await api.post(`purchase/flancepass`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function payFlancePassWithToken(payload) {
    try {
        const res = await api.patch(`purchase/flancepass/withToken`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function payFlancePassWithWallet(payload) {
    try {
        const res = await api.post(`purchase/flancepass/withWallet`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getUserFlancePass() {
    try {
        const res = await api.get(`flance/pass/user`);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function pausePass() {
    try {
        const res = await api.post(`flance/pass/pause`);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function resumePass() {
    try {
        const res = await api.post(`flance/pass/resume`);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function changePassPayment(payload) {
    try {
        const res = await api.post(`purchase/flancepass/change`, payload);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function changePassWithToken(payload) {
    try {
        const res = await api.post(
            `purchase/flancepass/change/withToken`,
            payload
        );
        return res;
    } catch (error) {
        throw error;
    }
}

export async function changePassWithWallet(payload) {
    try {
        const res = await api.post(
            `purchase/flancepass/change/withWallet`,
            payload
        );
        return res;
    } catch (error) {
        throw error;
    }
}

export async function renewPassPayment(payload) {
    try {
        const res = await api.post(`purchase/flancepass/renew`, payload);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function renewPassWithToken(payload) {
    try {
        const res = await api.post(
            `purchase/flancepass/renew/withToken`,
            payload
        );
        return res;
    } catch (error) {
        throw error;
    }
}

export async function renewPassWithWallet(payload) {
    try {
        const res = await api.post(
            `purchase/flancepass/renew/withWallet`,
            payload
        );
        return res;
    } catch (error) {
        throw error;
    }
}
