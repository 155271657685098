import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MoreIcon from "assets/svgs/mobile-nav-icon/more.svg";
import MoreActiveIcon from "assets/svgs/mobile-nav-icon/more-active.svg";
import HomeActiveIcon from "assets/svgs/sidebar-icon/home-active.svg";
import GymActiveIcon from "assets/svgs/sidebar-icon/gym-active.svg";
import HealthActiveIcon from "assets/svgs/sidebar-icon/health-active.svg";
import SavingActiveIcon from "assets/svgs/sidebar-icon/savings-active.svg";
import HomeIcon from "assets/svgs/mobile-nav-icon/home.svg";
import GymIcon from "assets/svgs/mobile-nav-icon/gym.svg";
import HealthIcon from "assets/svgs/mobile-nav-icon/health.svg";
import SavingIcon from "assets/svgs/mobile-nav-icon/savings.svg";
import { truncate } from "utils/functions";

export const MobileNav = ({ setShowMoreList, showMoreList, userBenefit }) => {
    const [sidebarList, setSidebarList] = useState([
        {
            name: "Home",
            path: "/",
            icon: HomeIcon,
            activeIcon: HomeActiveIcon,
        },
        {
            name: "Fitness",
            path: "/fitness",
            icon: GymIcon,
            activeIcon: GymActiveIcon,
        },
        {
            name: "Health Insurance",
            path: "/health-insurance",
            icon: HealthIcon,
            activeIcon: HealthActiveIcon,
        },
        {
            name: "Savings",
            path: "/savings",
            icon: SavingIcon,
            activeIcon: SavingActiveIcon,
        },
    ]);

    const moreList = {
        name: "More",
        path: "/billing",
        icon: MoreIcon,
        activeIcon: MoreActiveIcon,
    };
    const currentRoute = window.location.pathname;

    const handleActiveLink = (path) => {
        if (path.length === 1) {
            return currentRoute === path;
        } else {
            return currentRoute.includes(path.slice(0, 5), 0);
        }
    };

    useEffect(() => {
        let newSidebarList = [];
        if (userBenefit) {
            sidebarList.forEach((list) => {
                const found = userBenefit.find((benefit) => {
                    if (
                        benefit.benefitCategory === "Gym" &&
                        list.name === "Fitness"
                    ) {
                        return true;
                    } else {
                        return list.name === benefit.benefitCategory;
                    }
                });

                if (found) {
                    newSidebarList.push({
                        ...list,
                        name: `My ${list.name}`,
                        path: found.link,
                    });
                } else {
                    newSidebarList.push(list);
                }
            });

            setSidebarList(newSidebarList);
        }
    }, [userBenefit]);
    return (
        <ul className="mobnav">
            {sidebarList.map((list) => (
                <li className="mobnav__item" key={list.name}>
                    <NavLink
                        to={list.path}
                        exact
                        className={
                            handleActiveLink(list.path) && !showMoreList
                                ? "active-link"
                                : ""
                        }
                    >
                        <img
                            src={
                                handleActiveLink(list.path) && !showMoreList
                                    ? list.activeIcon
                                    : list.icon
                            }
                            alt=""
                        />
                        <span>
                            {list.name === "My Health Insurance"
                                ? truncate(list.name, 13, ".")
                                : list.name}
                        </span>
                    </NavLink>
                </li>
            ))}
            <div className="mobnav__item">
                <button
                    onClick={() => setShowMoreList(!showMoreList)}
                    className={showMoreList === true ? "active-link" : ""}
                >
                    <img
                        src={
                            showMoreList === true
                                ? moreList.activeIcon
                                : moreList.icon
                        }
                        alt=""
                    />
                    <span>{moreList.name}</span>
                </button>
            </div>
        </ul>
    );
};
