import React from "react";
import classnames from "classnames";
import Icon from "components/Icon";

export default ({ className, options = [], name, onChange }) => {
    return options.map((option, index) => (
        <label
            className={classnames("card-check", className)}
            key={option?.duration}
        >
            <input
                className="card-check__option"
                type="radio"
                name={name}
                value={option.duration}
                onChange={onChange}
                checked={option?.checked}
                disabled={option?.disabled}
            />
            <div
                className="card-check__value"
                style={{ backgroundColor: option?.disabled && "#F5F5F5" }}
            >
                <span className="card-check__checkmark"></span>
                <div className="card-check__value-text">
                    <p className="card-check__value-title">
                        {option?.textDuration || option?.duration}
                    </p>
                    <div className="card-check__value-subtext">
                        {option?.subtext}
                    </div>
                </div>
            </div>
        </label>
    ));
};
