import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "components/Icon";
import Logo from "assets/svgs/Flance-Logo__Full.svg";
import Help from "assets/svgs/help.svg";
import Arrow from "assets/svgs/arrow.svg";
import { truncate } from "utils/functions";

export default function DashboardSidebar({ userBenefit }) {
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [sidebarList, setSidebarList] = useState([
        {
            name: "Home",
            path: "/",
            icon: "sidebar-home",
            activeIcon: "sidebar-home-active",
        },
        {
            name: "Fitness",
            path: "/fitness",
            icon: "sidebar-gym",
            activeIcon: "sidebar-gym-active",
        },
        {
            name: "Health Insurance",
            path: "/health-insurance",
            icon: "sidebar-health",
            activeIcon: "sidebar-health-active",
        },
        {
            name: "Savings",
            path: "/savings",
            icon: "sidebar-savings",
            activeIcon: "sidebar-savings-active",
        },
        {
            name: "Billing",
            path: "/billing",
            icon: "sidebar-billing",
            activeIcon: "sidebar-billing-active",
        },
        // {
        //     name: "Referrals",
        //     path: "/settings",
        //     icon: "sidebar-referral",
        //     activeIcon: "sidebar-referral-active",
        // },
    ]);

    const currentRoute = window.location.pathname;

    const location = useLocation();

    useEffect(() => {
        setToggleSidebar(false);
    }, [location]);

    const handleActiveLink = (path) => {
        if (path.length === 1) {
            return currentRoute === path;
        } else {
            return currentRoute.includes(path.slice(0, 5), 0);
        }
    };

    useEffect(() => {
        let newSidebarList = [];
        if (userBenefit) {
            sidebarList.forEach((list) => {
                const found = userBenefit.find((benefit) => {
                    if (
                        benefit.benefitCategory === "Gym" &&
                        list.name === "Fitness"
                    ) {
                        return true;
                    } else {
                        return list.name === benefit.benefitCategory;
                    }
                });

                if (found) {
                    newSidebarList.push({
                        ...list,
                        name: `My ${list.name}`,
                        path: found.link,
                    });
                } else {
                    newSidebarList.push(list);
                }
            });

            setSidebarList(newSidebarList);
        }
    }, [userBenefit]);

    return (
        <nav
            className={`sidebar ${toggleSidebar === true ? "open" : ""}
        `}
        >
            <div className="sidebar__logo">
                <img src={Logo} alt="Flance Logo" />
            </div>
            <ul className="sidebar__list">
                <li>
                    <button
                        className="sidebar__control"
                        onClick={() => setToggleSidebar(!toggleSidebar)}
                    >
                        <img src={Arrow} alt="" />
                    </button>
                </li>
                {sidebarList.map((list) => (
                    <li className="sidebar__item" key={list.name}>
                        <NavLink
                            exact
                            className={
                                handleActiveLink(list.path) ? "active-link" : ""
                            }
                            to={list.path}
                        >
                            <Icon
                                id={
                                    handleActiveLink(list.path)
                                        ? list.activeIcon
                                        : list.icon
                                }
                                size="24"
                            />
                            <span>
                                {list.name === "My Health Insurance"
                                    ? truncate(list.name, 13, ".")
                                    : list.name}
                            </span>
                        </NavLink>
                    </li>
                ))}
            </ul>
            <div className="sidebar__divider"></div>
            <button
                className="sidebar__help"
                onClick={() => window.fcWidget.open()}
            >
                <img src={Help} alt="" />
                <span>Help</span>
            </button>
        </nav>
    );
}
