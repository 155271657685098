import React from "react";
import Logo from 'assets/svgs/Flance-Logo__Full.svg';

const GettingStartedHeader = (({children}) => {
    return (
        <nav className="topnav getting-started--nav">
            <div className="topnav--wrapper">
                <div className="brand">
                    <img src={Logo} alt="Flance Logo" />
                </div>
                <div className={`topnav-right d-iflx al-i-c`}>
                    {children}                    
                </div>
            </div>
        </nav>
    )
})

export default GettingStartedHeader;