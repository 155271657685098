import React, { useState, useEffect } from "react";

const ImageLoop = ({ image1, image2, duration }) => {
    const [showFirstImage, setShowFirstImage] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowFirstImage((prev) => !prev);
        }, duration);

        return () => clearInterval(interval);
    }, [duration]);

    return (
        <div className="image-container">
            <img
                src={image1}
                alt="Image 1"
                className={`image ${showFirstImage ? "fade-in" : "fade-out"}`}
            />
            <img
                src={image2}
                alt="Image 2"
                className={`image ${!showFirstImage ? "fade-in" : "fade-out"}`}
            />
        </div>
    );
};

export default ImageLoop;
