import { GoBack } from "components/GoBack";
import PurchaseModal from "components/modals/PurchaseBenefit";
import PassCard from "components/PassCard";
import React, { useEffect, useState } from "react";
import { getFlancePasses } from "services/flancepass";
import PageLoader from "components/PageLoader";
import { useQuery } from "utils/functions";

const ChoosePass = () => {
    const [passes, setPasses] = useState([]);
    const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
    const [pass, setPass] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const changePass = useQuery().get("changePass");

    function moveToFirst(arr, condition) {
        // Find the index of the object that matches the condition
        const index = arr.findIndex(condition);
        if (index === -1) return arr; // If not found, return the original array

        // Remove the object from its original position
        const [item] = arr.splice(index, 1);

        // Add the object to the beginning of the array
        arr.unshift(item);

        return arr;
    }

    const deleteNullValues = (obj) => {
        Object.keys(obj).forEach((key) => {
            if (obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            }
        });
        return obj;
    };

    const purchasePass = (pass) => {
        setPass(pass);
        setOpenPurchaseModal(true);
    };

    const fetchPasses = async () => {
        setIsLoading(true);
        try {
            const res = await getFlancePasses();
            const passes = res.data.map((pass) => {
                return {
                    ...pass,
                    icon: pass.name.toLowerCase().includes("standard")
                        ? "standard-pass"
                        : pass.name.toLowerCase().includes("premium")
                        ? "premium-pass"
                        : pass.name.toLowerCase().includes("lite")
                        ? "lite-pass"
                        : "basic-pass",
                    title: pass.name,
                    disabled:
                        changePass && pass.id === changePass ? true : false,
                    price: `₦${new Intl.NumberFormat().format(pass.price)}`,
                    benefits: Object.values(deleteNullValues(pass.whatYouGet)),
                    onViewFitnessCenters: `/choose-pass/${pass.id}${
                        changePass ? `?changePass=${changePass}` : ""
                    }`,
                    onPurchasePass: () => purchasePass(pass),
                };
            });

            setPasses(moveToFirst(passes, (pass) => pass.name === "Lite Pass"));
            return res;
        } catch (err) {
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPasses();
    }, []);
    return (
        <>
            <GoBack />

            <div className="detail--descrip">
                <h3>Choose a pass</h3>
                <p>
                    Choose a pass that suits your needs and access any fitness
                    center.
                </p>
            </div>

            {isLoading && <PageLoader />}
            {passes.length > 0 && (
                <div className="choosepass">
                    {passes.map((pass, index) => (
                        <PassCard key={index} {...pass} />
                    ))}
                </div>
            )}
            {openPurchaseModal && (
                <PurchaseModal
                    openPurchaseModal={openPurchaseModal}
                    setOpenPurchaseModal={setOpenPurchaseModal}
                    flancePass={pass}
                />
            )}
        </>
    );
};

export default ChoosePass;
