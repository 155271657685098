import api from "./api";

export async function getWallet() {
    try {
        const res = await api.get(`wallet`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function createWallet() {
    try {
        const res = await api.post(`wallet`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getUserBank() {
    try {
        const res = await api.get(`wallet/bank`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getWalletTransaction() {
    try {
        const res = await api.get(`wallet/transactions`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getBank() {
    try {
        const res = await api.get(`bank`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function addWalletBank(payload) {
    try {
        const res = await api.post(`wallet/bank`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function withdrawToBank(payload) {
    try {
        const res = await api.post(`wallet/withdraw`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function setUserDefaultBank(payload) {
    try {
        const res = await api.put(`wallet/bank/primary`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}
