import Button from "components/Buttons";
import Icon from "components/Icon";
import React from "react";

const SideModal = ({
    openSideModal,
    setOpenSideModal,
    headerText,
    children,
    isCart,
    handleClick,
    btnDisbled,
    isLoading,
    btnText,
    closeSideModal,
}) => {
    return (
        <div className={`cart__container ${openSideModal ? "backdrop" : ""}`}>
            <div className={`sidenav ${openSideModal ? "collapsed" : ""}`}>
                <div className="cart__container--header">
                    {openSideModal && (
                        <div className="close__btn">
                            <Icon
                                id="close"
                                width="32"
                                height="32"
                                onClick={closeSideModal}
                            />
                        </div>
                    )}

                    <div className="__details">
                        {isCart && (
                            <Icon
                                id="cart-blue"
                                width="23"
                                height="21"
                                onClick={closeSideModal}
                            />
                        )}

                        <p>{headerText}</p>
                    </div>
                </div>
                <div className="cart-items">{children}</div>
                {btnText ? (
                    <div className="cart__container--footer">
                        <Button
                            variant="primary"
                            onClick={handleClick}
                            disabled={btnDisbled || isLoading}
                        >
                            {isLoading ? "Loading..." : `${btnText}`}
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default SideModal;
