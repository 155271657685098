import React from "react";
import Slider from "react-slick";
import AuthFlancePass from "assets/svgs/auth-flance-pass.svg";
import AuthGym from "assets/svgs/auth-gym.svg";
import AuthTestifyOne from "assets/svgs/auth-testify-one.svg";
import AuthTestifyTwo from "assets/svgs/auth-testify-two.svg";
import AuthLogosTwo from "assets/svgs/auth-logos-two.svg";
import AuthLogosOne from "assets/svgs/auth-logos-one.svg";
import ImageLoop from "./ImageLoop";

export const AuthCarousel = () => {
    const mainSliderSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="auth__aside-slider-dots"></div>,
    };

    const sliderItems = [
        {
            title: "Your Fitness, Your Way.",
            description:
                "Get a pass to access any fitness center for as low as ₦10k/month.",
            imageOne: AuthFlancePass,
            imageTwo: AuthGym,
        },
        {
            title: "Get affordable fitness options.",
            description:
                "Discover gym discounts on Flance, starting at ₦10k/month.",
            imageOne: AuthLogosTwo,
            imageTwo: AuthLogosOne,
        },
        {
            title: "Access Other Benefits.",
            description:
                "Browse through our amazing offers, and start enjoying value.",
            imageOne: AuthTestifyOne,
            imageTwo: AuthTestifyTwo,
        },
    ];
    return (
        <div className="auth__aside-slider-wrap">
            <Slider {...mainSliderSettings}>
                {sliderItems.map((item, index) => {
                    return (
                        <div key={index} className="auth__aside-slider">
                            <div>
                                <div className="auth__aside-slider-sub">
                                    <div>
                                        <ImageLoop
                                            image1={item.imageOne}
                                            image2={item.imageTwo}
                                            duration={5000}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h1 className="auth__aside-slider-title">
                                        {item.title}
                                    </h1>
                                    <p className="auth__aside-slider-desc">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};
