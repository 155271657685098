import React, { useEffect, useRef } from "react";

function LogoSlider({ images }) {
    const logosRef = useRef(null);

    useEffect(() => {
        const copy = logosRef.current.cloneNode(true);
        logosRef.current.parentNode.appendChild(copy);
    }, []);

    return (
        <div className="logos">
            <div className="logos-slide" ref={logosRef}>
                {images.map((image, index) => (
                    <img key={index} src={image} alt="Logo" />
                ))}
            </div>
        </div>
    );
}

export default LogoSlider;
