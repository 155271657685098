import Button from "components/Buttons";
import Modal from "components/Modal";
import React from "react";
import LogoutIcon from "assets/svgs/warning-icon.svg";
import AuthService from "../../services/auth";
import { useHistory } from "react-router-dom";

export const LogoutModal = ({ logoutModal, setLogoutModal }) => {
    const history = useHistory();

    const logout = () => {
        localStorage.removeItem("userPlan");
        localStorage.removeItem("user");
        localStorage.removeItem("gymInfo");
        history.push("/login");
        AuthService.logout()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };
    return (
        <Modal
            isHeader={false}
            handleClose={() => {
                setLogoutModal(false);
            }}
            show={logoutModal}
        >
            <div className="mobnav__logout">
                <div className="mobnav__logout-icon">
                    <img src={LogoutIcon} alt="logout" />
                </div>
                <h3>Are you sure</h3>
                <p>Are you sure you want to logout</p>
                <div className="mobnav__logout-action">
                    <Button className="" onClick={logout}>
                        Yes, Logout
                    </Button>
                    <Button className="" onClick={() => setLogoutModal(false)}>
                        no, don’t Logout
                    </Button>
                </div>
            </div>

            {/* <Button variant={"primary"} block onClick={buttonAction}>
                {buttonText}
            </Button> */}
        </Modal>
    );
};
