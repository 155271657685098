import api from "./api";
import axios from "axios";

export function purchasePlan(payload, token) {
    return axios
        .post(process.env.REACT_APP_BASE_URL + "purchase/plan", payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}

export function confirmPayment(payload) {
    return api
        .post("purchase/payment/notification", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getUserPaymentDetails() {
    try {
        const response = await api.get("user/payment/option/card");
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getUserPaymentOptions() {
    try {
        const response = await api.get("user/payment/option");
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getUserBillingHistory() {
    try {
        const response = await api.get("billing/history");
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getUserPaymentOptionDefault(defaultOption) {
    try {
        const response = await api.put(
            `user/payment/option/default/${defaultOption}`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function deactivatePaymentMethod() {
    try {
        const response = await api.put("user/payment/option/deactivate");
        return response;
    } catch (error) {
        throw error;
    }
}

export async function activatePaymentMethod() {
    try {
        const response = await api.put("user/payment/option/activate");
        return response;
    } catch (error) {
        throw error;
    }
}

export async function planPurchaseWithToken(payload) {
    try {
        const response = await api.post("purchase/plan/withToken", payload);
        return response;
    } catch (error) {
        throw error;
    }
}
