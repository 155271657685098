import React from "react";
import GettingStartedHeader from "components/GettingStartedHeader";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";

const GettingStartedLayout = withRouter(
    ({ isLogin, isSignup, history, children }) => {
        // console.log(isLogin, isSignup, history)

        const isRedBg = history.location.pathname;
        // const isWhite = history.location.pathname;
        return (
            <div
                className={`getting-started ${
                    isRedBg === "/signup/choose-plan" ? "redbg" : ""
                }`}
            >
                <GettingStartedHeader>
                    {isLogin ? (
                        <p>
                            Need help?{" "}
                            <Link className="btn btn--link" to="">
                                Chat with us
                            </Link>
                        </p>
                    ) : isSignup ? (
                        <Link className="btn btn--link" to="/login">
                            Log in
                        </Link>
                    ) : null}
                </GettingStartedHeader>
                <main className="getting-started--main">{children}</main>
            </div>
        );
    }
);

GettingStartedLayout.protoTypes = {
    isLogin: propTypes.bool,
    isSignup: propTypes.bool,
};

export default GettingStartedLayout;
