import React from "react";
import Icon from "./Icon";
import { useHistory } from "react-router-dom";

export const GoBack = ({ title = "Back" }) => {
    const history = useHistory();
    return (
        <div className="back-btn">
            <button
                type="button"
                onClick={() => {
                    history.goBack();
                }}
            >
                <Icon id="go-back" width="14" height="16" />
                {title}
            </button>
        </div>
    );
};
