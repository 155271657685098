import React, { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import { UserBenefitContext, UserDetailContext } from "context";
import { getBenefits } from "services/benefits";
import NProgress from "nprogress";
import { withRouter, NavLink, useLocation } from "react-router-dom";
import DashboardSidebar from "./DashboardSidebar";
import { MobileNav } from "./MobileNav";
import BillingIcon from "assets/svgs/sidebar-icon/billing.svg";
import BillingActiveIcon from "assets/svgs/sidebar-icon/billing-active.svg";
import SettingIcon from "assets/svgs/mobile-nav-icon/setting.svg";
import SettingActiveIcon from "assets/svgs/mobile-nav-icon/setting-active.svg";
import LogoutIcon from "assets/svgs/mobile-nav-icon/logout.svg";
import { LogoutModal } from "components/modals/LogoutModal";
import Help from "assets/svgs/help.svg";

const DashboardLayout = withRouter(
    ({ navClassName, extraClassName, pageTitle, children }) => {
        const [userBenefit, setUserBenefit] = useState(null);
        const [userDetail, setUserDetail] = useState(null);
        const [showMoreList, setShowMoreList] = useState(false);
        const [logoutModal, setLogoutModal] = useState(false);
        const navList = [
            {
                name: "Billing",
                path: "/billing",
                icon: BillingIcon,
                activeIcon: BillingActiveIcon,
            },
            // {
            //     name: "Referrals",
            //     path: "/referrals",
            //     icon: ReferralIcon,
            //     activeIcon: ReferralActiveIcon,
            // },
            {
                name: "Settings",
                path: "/settings",
                icon: SettingIcon,
                activeIcon: SettingActiveIcon,
            },
        ];

        const location = useLocation();

        useEffect(() => {
            setShowMoreList(false);
        }, [location]);
        const setBenefitLink = (benefit) => {
            switch (benefit.benefitCategory) {
                case "Health Insurance":
                    return `/health-insurance/${benefit.benefitId}/${
                        benefit.id
                    }${
                        benefit?.businessBenefit === true
                            ? "?isBusinessBenefit=" + benefit?.businessBenefit
                            : ""
                    }`;
                case "Savings":
                    return `/savings/${benefit.benefitId}/${benefit.id}${
                        benefit?.businessBenefit === true
                            ? "?isBusinessBenefit=" + benefit?.businessBenefit
                            : ""
                    }`;
                case "Gym":
                    return `/fitness/${benefit.benefitId}/${benefit.id}${
                        benefit?.businessBenefit === true
                            ? "?isBusinessBenefit=" + benefit?.businessBenefit
                            : ""
                    }`;
                default:
                    return "/";
            }
        };
        const getUserBenefit = async () => {
            try {
                const response = await getBenefits();
                if (response.data.userBenefits?.length > 0) {
                    const benefits = response.data.userBenefits?.map(
                        (benefit) => {
                            return {
                                ...benefit,
                                link: setBenefitLink(benefit),
                            };
                        }
                    );

                    setUserBenefit(benefits);
                }
                NProgress.done();
            } catch (error) {
                NProgress.done();
                if (error?.response?.status === 404) {
                    setUserBenefit(null);
                }
                return error;
            }
        };
        useEffect(() => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user) {
                const userDetail = {
                    id: user.id,
                    email: user.username,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    hasActivePlan: user.hasActivePlan,
                };
                setUserDetail(userDetail);
                getUserBenefit();
            }
        }, []);

        const currentRoute = window.location.pathname;

        const logoutAction = () => {
            setLogoutModal(true);
            setShowMoreList(false);
        };
        return (
            <div>
                <UserDetailContext.Provider
                    value={{ userDetail, setUserDetail }}
                >
                    <UserBenefitContext.Provider
                        value={{ userBenefit, getUserBenefit }}
                    >
                        <div className="main">
                            <DashboardSidebar userBenefit={userBenefit} />
                            <div className="main__right">
                                <DashboardHeader
                                    navClassName={navClassName}
                                    pageTitle={pageTitle}
                                    showMoreList={showMoreList}
                                    setShowMoreList={setShowMoreList}
                                />
                                <MobileNav
                                    showMoreList={showMoreList}
                                    setShowMoreList={setShowMoreList}
                                    userBenefit={userBenefit}
                                />

                                {showMoreList && (
                                    <div className="mobnav__more">
                                        <ul className="mobnav__more-list">
                                            {navList.map((list) => (
                                                <li
                                                    className="mobnav__more-item"
                                                    key={list.name}
                                                >
                                                    <NavLink to={list.path}>
                                                        <img
                                                            src={
                                                                currentRoute ===
                                                                list.path
                                                                    ? list.activeIcon
                                                                    : list.icon
                                                            }
                                                            alt=""
                                                        />
                                                        <span>{list.name}</span>
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="mobnav__more-item">
                                            <button
                                                onClick={() => logoutAction()}
                                            >
                                                <img src={LogoutIcon} alt="" />
                                                <span>Logout</span>
                                            </button>
                                        </div>

                                        <div className="sidebar__divider"></div>
                                        <button
                                            className="sidebar__help"
                                            onClick={() =>
                                                window.fcWidget.open()
                                            }
                                        >
                                            <img src={Help} alt="" />
                                            <span>Help</span>
                                        </button>
                                    </div>
                                )}

                                <main
                                    className={`main__body ${extraClassName}`}
                                >
                                    {children}
                                </main>
                            </div>
                        </div>
                        <LogoutModal
                            logoutModal={logoutModal}
                            setLogoutModal={setLogoutModal}
                        />
                    </UserBenefitContext.Provider>
                </UserDetailContext.Provider>
            </div>
        );
    }
);

export default DashboardLayout;
