import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import AuthLayout from "layouts/AuthLayout";
import GettingStartedLayout from "layouts/GettingStartedLayout";
import PrivateRoute from "./PrivateRoute";
import DashboardLayout from "layouts/DashboardLayout";
import NProgress from "nprogress";
import { NonSidebarLayout } from "layouts/NonSidebarLayout";
import ChoosePass from "pages/app/FlancePass/ChoosePass";
// import history from "utils/history";

const LazyLoad = () => {
    React.useEffect(() => {
        NProgress.start();
        return () => {
            NProgress.done();
        };
    });

    return "";
};
//Referencing pages
//Auths..
const Signup = lazy(() => import("pages/auth/Signup"));
const VerifyEmail = lazy(() => import("pages/auth/VerifyEmail"));
const VerifyNameChange = lazy(() => import("pages/auth/VerifyNameChange"));
const Login = lazy(() => import("pages/auth/Login"));
const ForgotPassword = lazy(() => import("pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/auth/ResetPassword"));

//Getting started
const PlanDetails = lazy(() => import("pages/auth/gettingStarted/PlanDetails"));
const ChoosePlan = lazy(() => import("pages/auth/gettingStarted/ChoosePlan"));

//Home
const Home = lazy(() => import("pages/app/home/Home"));
const Gym = lazy(() => import("pages/app/home/Gym"));
const Explore = lazy(() => import("pages/app/explore/Explore"));
const AccountSetup = lazy(() => import("pages/app/home/AccountSetup"));
const BoltAccountSetup = lazy(() => import("pages/app/home/bolt/AccountSetup"));
const PersonalInfo = lazy(() => import("pages/app/home/PersonalInfo"));

const PreHomeBolt = lazy(() => import("pages/app/home/bolt/PreHome"));
const RenewalForm = lazy(() => import("pages/app/home/RenewalForm"));
const BenefitsPage = lazy(() => import("pages/app/home/Benefits"));
const IndividualGym = lazy(() => import("pages/app/home/IndividualGym"));
const Billings = lazy(() => import("pages/app/home/Billings"));
const TaxAdvisory = lazy(() => import("pages/app/home/TaxAdvisory"));

//Details
const HealthInsurance = lazy(() =>
    import("pages/app/benefitDetails/HealthInsurance")
);
const gymDetails = lazy(() => import("pages/app/benefitDetails/Gym"));
// const HealthBeneficiary = lazy(() => import("components/HealthBeneficiary"));
const HealthBeneficiaryDetail = lazy(() =>
    import("pages/app/benefitDetails/HealthBeneficiaryDetail")
);
const HealthBeneficiaryForm = lazy(() =>
    import("pages/app/benefitDetails/HealthBeneficiaryForm")
);
const HealthClaim = lazy(() => import("pages/app/makeClaim/HealthClaim"));
const PhoneClaim = lazy(() => import("pages/app/makeClaim/DeviceClaim"));

const SavingsInvestment = lazy(() =>
    import("pages/app/benefitDetails/Savings")
);

const PhoneInsurance = lazy(() =>
    import("pages/app/benefitDetails/PhoneInsurance")
);

//Settings
const Settings = lazy(() => import("pages/app/settings/Settings"));
const Error404 = lazy(() => import("pages/404"));

//Wallets
const WalletTransaction = lazy(() =>
    import("pages/app/wallet/WalletTransaction")
);

//Savings
const Savings = lazy(() => import("pages/app/savings/Savings"));

//health
const Health = lazy(() => import("pages/app/health/Health"));

// const ChoosePass = lazy(() => import("pages/app/FlancePass/ChoosePass"));

const ChoosePassFitness = lazy(() =>
    import("pages/app/FlancePass/ChoosePassFitness")
);

const UserFlancePass = lazy(() =>
    import("pages/app/FlancePass/FlancePassDetails")
);

const baseName = process.env.PUBLIC_URL;

//Creating Routes
const Routes = () => {
    return (
        <>
            <Router basename={baseName}>
                <Suspense fallback={<LazyLoad />}>
                    <Switch>
                        {/* can't access them when you are logged in */}
                        {/* <AuthRoute
                            exact
                            path="/"
                            component={Login}
                            layout={AuthLayout}
                        /> */}

                        <AuthRoute
                            exact
                            path="/login"
                            component={Login}
                            layout={AuthLayout}
                        />
                        <AuthRoute
                            exact
                            path="/signup"
                            component={Signup}
                            layout={AuthLayout}
                        />
                        <AuthRoute
                            exact
                            path="/verifyemail"
                            component={VerifyEmail}
                            layout={AuthLayout}
                        />
                        <AuthRoute
                            exact
                            path="/Verifyname"
                            component={VerifyNameChange}
                            layout={AuthLayout}
                        />

                        <AuthRoute
                            exact
                            path="/forgot-password"
                            component={ForgotPassword}
                            layout={AuthLayout}
                        />

                        <AuthRoute
                            exact
                            path="/reset-password"
                            component={ResetPassword}
                            layout={AuthLayout}
                        />

                        <AuthRoute
                            exact
                            path="/signup/plan-details/:id"
                            component={PlanDetails}
                            layout={GettingStartedLayout}
                            isLogin={true}
                            isSignup={false}
                        />

                        <AuthRoute
                            exact
                            path="/signup/choose-plan"
                            component={ChoosePlan}
                            layout={GettingStartedLayout}
                            isLogin={false}
                            isSignup={true}
                        />

                        {/* can only access them when you are logged in */}

                        {/* Home */}
                        <PrivateRoute
                            exact
                            path="/"
                            component={Home}
                            layout={DashboardLayout}
                            pageTitle="Home"
                        />

                        <PrivateRoute
                            exact
                            path="/savings"
                            component={Savings}
                            layout={DashboardLayout}
                            pageTitle="Savings"
                        />

                        <PrivateRoute
                            exact
                            path="/health-insurance"
                            component={Health}
                            layout={DashboardLayout}
                            pageTitle="Health Insurance"
                        />
                        <PrivateRoute
                            exact
                            path="/fitness"
                            component={Gym}
                            layout={DashboardLayout}
                            pageTitle="Find Fitness Centers"
                        />
                        <PrivateRoute
                            exact
                            path="/billing"
                            component={Billings}
                            layout={DashboardLayout}
                            pageTitle="Billings"
                        />

                        <PrivateRoute
                            exact
                            path="/app/explore"
                            component={Explore}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/explore/plan-details/:id"
                            component={PlanDetails}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/account-setup"
                            component={AccountSetup}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/tax-advisory"
                            component={TaxAdvisory}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/bolt/account-setup"
                            component={BoltAccountSetup}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/account-setup/personal"
                            component={PersonalInfo}
                            layout={DashboardLayout}
                        />

                        {/* Bolt home */}
                        <PrivateRoute
                            exact
                            path="/awaiting-activation"
                            component={PreHomeBolt}
                            layout={DashboardLayout}
                            navClassName="preBoltNav"
                            extraClassName="boltPreHomeMain"
                        />

                        {/* Renewal form */}
                        <PrivateRoute
                            exact
                            path="/renew"
                            component={RenewalForm}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/bolt-deals"
                            component={PreHomeBolt}
                            layout={DashboardLayout}
                            navClassName="preBoltNav"
                            extraClassName="boltPreHomeMain"
                        />

                        {/* Benefit Details */}
                        <PrivateRoute
                            exact
                            path="/health-insurance/:benefitId/:id"
                            component={HealthInsurance}
                            layout={DashboardLayout}
                            pageTitle="My health insurance"
                        />

                        <PrivateRoute
                            exact
                            path="/fitness/:benefitId/:id"
                            component={gymDetails}
                            layout={DashboardLayout}
                            pageTitle="My Fitness"
                        />
                        {/* <PrivateRoute
                            exact
                            path="/beneficiary/:primaryUserBenefitId/:id"
                            component={HealthBeneficiary}
                            layout={DashboardLayout}
                        /> */}
                        <PrivateRoute
                            exact
                            path="/health-insurance/beneficiary/details/:beneficiaryId/:benefitId"
                            component={HealthBeneficiaryDetail}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/health-insurance/beneficiary/:id/:primaryUserBenefitId/add-health-beneficiary"
                            component={HealthBeneficiaryForm}
                            layout={DashboardLayout}
                            pageTitle="Add beneficiary"
                        />

                        {/* <PrivateRoute
                            exact
                            path="/app/benefit/make-a-claim-health"
                            component={HealthClaim}
                            layout={DashboardLayout}
                        /> */}
                        <PrivateRoute
                            exact
                            path="/app/benefit/make-a-claim-phone/:id"
                            component={PhoneClaim}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/app/benefit/phone/:id"
                            component={PhoneInsurance}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/savings/:benefitId/:id"
                            component={SavingsInvestment}
                            layout={DashboardLayout}
                            pageTitle="My savings"
                        />

                        {/* Settings */}
                        <PrivateRoute
                            exact
                            path="/settings"
                            component={Settings}
                            layout={DashboardLayout}
                            pageTitle="Settings"
                        />

                        <PrivateRoute
                            exact
                            path="/settings/:tabs"
                            component={Settings}
                            layout={DashboardLayout}
                            pageTitle="Settings"
                        />

                        <PrivateRoute
                            exact
                            path="/fitness/:id"
                            component={IndividualGym}
                            layout={DashboardLayout}
                        />

                        {/*Wallet*/}
                        <PrivateRoute
                            exact
                            path="/transactions"
                            component={WalletTransaction}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/choose-pass"
                            component={ChoosePass}
                            layout={NonSidebarLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/choose-pass/:id"
                            component={ChoosePassFitness}
                            layout={NonSidebarLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/pass/:passId/:id"
                            component={IndividualGym}
                            layout={NonSidebarLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/flance-pass/:id"
                            component={UserFlancePass}
                            layout={NonSidebarLayout}
                        />

                        {/* catch all invalid urls */}
                        <Route path="*" component={Error404} />
                    </Switch>
                </Suspense>
            </Router>
        </>
    );
};

export default Routes;
