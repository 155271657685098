import React from "react";
import PropTypes from "prop-types";
import icons from "assets/icon-sprite.svg";

function Icon({
    width,
    height,
    id,
    className,
    onClick,
    size,
    disabled,
    color,
}) {
    return (
        <svg
            width={size ?? width}
            height={size ?? height}
            className={`${className ? className : ""} ${
                disabled ? "opacity-7" : ""
            }`}
            onClick={onClick}
            fill={color}
        >
            <use xlinkHref={`${icons}#${id}`} />
        </svg>
    );
}

Icon.propTypes = {
    id: PropTypes.string,
};

export default Icon;
